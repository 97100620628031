var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-layout', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('console-title', [_c('span', [_vm._v("주문명칭")])]), _c('console-body', [_c('v-card', [_c('v-card-title', {
    staticClass: "d-flex align-center py-2"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("금액대별 주문명칭(상품권)")]), _c('v-spacer'), _c('span', [_c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.create(`range`);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("추가")], 1)], 1)], 1), _c('v-divider'), _c('v-data-table', {
    attrs: {
      "items": _vm.orderNames.filter(function (orderName) {
        return orderName.type == `range`;
      }),
      "headers": _vm.orderNamesHeaders,
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "item.min-max",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.min.format()) + "(이상) ~ " + _vm._s(item.max.format()) + "(이하) ")];
      }
    }, {
      key: "item.actions",
      fn: function (_ref2) {
        var item = _ref2.item,
          index = _ref2.index;
        return _c('span', {}, [_c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.details(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item, index);
            }
          }
        }, [_vm._v("mdi-delete")])], 1);
      }
    }])
  })], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center py-2"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("랜덤 주문명칭(포인트)")]), _c('v-spacer'), _c('span', [_c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.create(`random`);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("추가")], 1)], 1)], 1), _c('v-divider'), _c('v-data-table', {
    attrs: {
      "items": _vm.orderNames.filter(function (orderName) {
        return orderName.type == `random`;
      }),
      "headers": _vm.orderNamesHeaders2,
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "item.min-max",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.min.format()) + "(이상) ~ " + _vm._s(item.max.format()) + "(이하) ")];
      }
    }, {
      key: "item.actions",
      fn: function (_ref4) {
        var item = _ref4.item,
          index = _ref4.index;
        return _c('span', {}, [_c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.details(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item, index);
            }
          }
        }, [_vm._v("mdi-delete")])], 1);
      }
    }])
  })], 1)], 1), _c('order-name-dialog', {
    ref: "orderNameDialog",
    attrs: {
      "orderNames": _vm.orderNames
    },
    on: {
      "save": function ($event) {
        return _vm.search();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }