var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.date,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.date = $event;
      },
      "update:return-value": function ($event) {
        _vm.date = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "pt-0",
          attrs: {
            "value": _vm.formatData,
            "append-icon": "event",
            "readonly": ""
          }
        }, 'v-text-field', _vm.$attrs, false), on))];
      }
    }])
  }, [_c('v-date-picker', {
    attrs: {
      "type": "date",
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.menu.save(_vm.date);
      }
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }