<template>
    <v-menu ref="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field :value="formatData" append-icon="event" readonly v-bind="$attrs" v-on="on" class="pt-0" ></v-text-field>
        </template>
        <v-date-picker v-model="date" type="date" no-title scrollable @input="$refs.menu.save(date)"></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: {
        value: { type: String, default: undefined },
    },
    data(){
        return {
            date: this.$props.value
        };
    },
    watch: {
        value(){
            this.date = this.value;
        },
        date(){
            this.$emit('input', this.date);
        }
    },
    computed: {
        formatData(){
            return this.date?.substr(0, 10);
        }
    }
}
</script>
