<template>
    <console-layout max-width="1024">

        <console-title>
            <span>주문명칭</span>
        </console-title>

        <console-body>
            <v-card>
                <v-card-title class="d-flex align-center py-2">
                    <span class="subtitle-2">금액대별 주문명칭(상품권)</span>
                    <v-spacer />
                    <span>
                        <v-btn color="accent" @click="create(`range`)"><v-icon left>mdi-plus</v-icon>추가</v-btn>
                    </span>
                </v-card-title>
                <v-divider />
                <v-data-table :items="orderNames.filter(orderName => orderName.type == `range`)" :headers="orderNamesHeaders" hide-default-footer disable-sort disable-pagination>
                    <template slot="item.min-max" slot-scope="{item}">
                        {{ item.min.format() }}(이상) ~ {{ item.max.format() }}(이하)
                    </template>
                    <span slot="item.actions" slot-scope="{item, index}">
                        <v-icon small @click="details(item)" class="mx-1">mdi-pencil</v-icon>
                        <v-icon small @click="remove(item, index)" class="mx-1">mdi-delete</v-icon>
                    </span>
                </v-data-table>
            </v-card>
            
            <v-card class="mt-4">
                <v-card-title class="d-flex align-center py-2">
                    <span class="subtitle-2">랜덤 주문명칭(포인트)</span>
                    <v-spacer />
                    <span>
                        <v-btn color="accent" @click="create(`random`)"><v-icon left>mdi-plus</v-icon>추가</v-btn>
                    </span>
                </v-card-title>
                <v-divider />
                <v-data-table :items="orderNames.filter(orderName => orderName.type == `random`)" :headers="orderNamesHeaders2" hide-default-footer disable-sort disable-pagination>
                    <template slot="item.min-max" slot-scope="{item}">
                        {{ item.min.format() }}(이상) ~ {{ item.max.format() }}(이하)
                    </template>
                    <span slot="item.actions" slot-scope="{item, index}">
                        <v-icon small @click="details(item)" class="mx-1">mdi-pencil</v-icon>
                        <v-icon small @click="remove(item, index)" class="mx-1">mdi-delete</v-icon>
                    </span>
                </v-data-table>
            </v-card>

        </console-body>

        <order-name-dialog ref="orderNameDialog" :orderNames="orderNames" @save="search()"/>

    </console-layout>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import OrderNameDialog from "@/components/console/gshop/setting/OrderNameDialog.vue";

export default {
    components: {
        ConsoleContent,
        VDateField,
        OrderNameDialog
    },
    data(){
        return {
            orderNames: [],
            orderNamesHeaders: [
                { text: "금액구간", value: "min-max", align: "center", /* width: 10 */ },
                { text: "주문명", value: "name", align: "center", /* width: 10 */ },
                { text: "actions", value: "actions", align: "center", /* width: 10 */ },
            ],
            orderNamesHeaders2: [
                { text: "주문명", value: "name", align: "center", /* width: 10 */ },
                { text: "actions", value: "actions", align: "center", /* width: 10 */ },
            ],
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search();
        },
        async search(){
            let { setting: { orderNames } } = await api.console.gshop.setting.get();

            this.orderNames = orderNames;
        },
        async remove(orderName, index){
            if(confirm("삭제하시겠습니까?")){
                this.orderNames.splice(index, 1);
    
                await api.console.gshop.setting.put({ orderNames: this.orderNames })
            }
        },
        create(type){
            this.$refs.orderNameDialog.type = type;
            this.$refs.orderNameDialog.open();
        },
        details(orderName){
            this.$refs.orderNameDialog.index = this.orderNames.indexOf(orderName);
            this.$refs.orderNameDialog.type = orderName.type;
            this.$refs.orderNameDialog.orderName = JSON.parse(JSON.stringify(orderName));
            this.$refs.orderNameDialog.open();
        }
    }
}
</script>
