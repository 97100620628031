<template>
    <v-dialog v-model="show" max-width="500" :persistent="loading">
        <v-card>
            <v-card-title>
                <span class="subtitle-2">주문명 & 금액구간 설정</span>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="orderName.name" label="주문명" persistent-placeholder dense hide-details class="mt-4" />

                <v-layout v-if="type == `range`" class="mt-6">
                    <v-text-field v-model="orderName.min" label="주문금액" persistent-placeholder dense :messages="`${parseInt(orderName.min).format()} 원 이상`" @change="value => orderName.min = String(value).replace(/[^0-9]/g, '') || 0"/>
                    <span class="shrink px-4">~</span>
                    <v-text-field v-model="orderName.max" persistent-placeholder dense :messages="`${parseInt(orderName.max).format()} 원 이하`" @change="value => orderName.max = String(value).replace(/[^0-9]/g, '') || 0"/>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text color="grey darken-2" :disabled="loading" @click="close()">취소</v-btn>
                <v-btn text color="primary" :loading="loading" @click="save()">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
export default {
    props: {
        orderNames: { type: Array, default: () => [] },
    },
    data(){
        return {
            show: false,

            loading: false,
            type: null,

            index: undefined,
            orderName: {
                name: "",
                min: 0,
                max: 0,
            },
        }
    },
    methods: {
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        },
        validate(){
            try{
                if(!this.orderName.name) throw new Error("주문명을 입력해주세요");
                if(this.type == `ramge` && Number.isNaN(this.orderName.min)) throw new Error("최소 금액을 확인해주세요");
                if(this.type == `ramge` && Number.isNaN(this.orderName.max)) throw new Error("최대 금액을 확인해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },
        save(){
            this.loading = true;

            if(this.validate()){
                let orderNames = [...this.orderNames];

                orderNames[this.index ?? this.orderNames.length] = { ...this.orderName, type: this.type };
    
                api.console.gshop.setting.put({ orderNames })
                .then(() => {
                    this.$emit("save");
                    this.close();
                })
                .finally(() => {
                    this.loading = false;
                });
            }
        }
    },
    watch: {
        show(){
            if(!this.show){
                this.index = undefined;
                this.orderName = {
                    name: "",
                    min: 0,
                    max: 0 
                }
            }
        }                                                             
    }
}
</script>